export default [
  // {
  //   path: '/',
  //   text: 'home',
  // },
  {
    path: '/book',
    text: 'Book',
  },
  {
    path: '/bio',
    text: 'Bio',
  },
    {
    path: '/press',
    text: 'press',
  },
  //     {
  //   path: '/speaker',
  //   text: 'speaker',
  // },
  {
    path: '/contact',
    text: 'Contact',
  },
];
